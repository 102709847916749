import React from 'react'
import { graphql } from 'gatsby'
import SEO from '~/components/00-global/util/seo'
import FeaturedProducts from '~/components/02-shop/featuredProducts.js'
import Byproducts from '~/components/02-shop/byproducts.js'
import PageHeader from '~/components/00-global/PageHeader/PageHeader'
import { Load } from '~/components/00-global/Animation/Animation'

import { useIntl } from 'gatsby-plugin-intl'

const ShopPage = ({ data }) => {
  const intl = useIntl()

  const findFeaturedProduct = featuredProduct =>
    data.products.nodes.filter(
      product =>
        product.productType === featuredProduct &&
        product.locale === intl.locale
    )

  let featuredProducts = []
  featuredProducts.push({
    products: findFeaturedProduct('Biersafe'),
    imageFluid: data.biersafeImage.childImageSharp.fluid
  })

  featuredProducts.push({
    products: findFeaturedProduct('Weinsafe'),
    imageFluid: data.weinsafeImage.childImageSharp.fluid
  })

  featuredProducts.push({
    products: findFeaturedProduct('HopfenHöhle'),
    imageFluid: data.hopfenhoehle.childImageSharp.fluid
  })

  const collections = data.collections.nodes.filter(
    collection => collection.locale === intl.locale
  )

  return (
    <>
      <Load>
        <SEO
          title={intl.formatMessage({ id: 'shop.header.title' })}
          description={intl.formatMessage({ id: 'shop.header.subtitle' })}
          pathname={`/shop`}
        />
        <PageHeader
          title={intl.formatMessage({ id: 'shop.header.title' })}
          text={intl.formatMessage({ id: 'shop.header.subtitle' })}
          image={data.imagePageHeader.childImageSharp.fluid}
          xPosMob='75%'
          alt={intl.formatMessage({ id: 'shop.header.image' })}
        />

        <FeaturedProducts products={featuredProducts} />
        <Byproducts collections={collections} />
      </Load>
    </>
  )
}

export default ShopPage

export const {
  products,
  collections,
  biersafeImage,
  weinsafeImage,
  captorImage,
  imagePageHeader
} = graphql`
  query {
    imagePageHeader: file(
      relativePath: { eq: "image-handmade-background.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    collections: allShopifyCollection {
      nodes {
        title
        locale
        descriptionHtml
        products {
          id
          locale
          shopifyId
          title
          productType
          handle
          createdAt
          images {
            id
            originalSrc
            localFile {
              childImageSharp {
                fluid(maxWidth: 210) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          variants {
            price {
              amount
            }
          }
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    products: allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
      nodes {
        id
        locale
        shopifyId
        title
        productType
        handle
        createdAt
        images {
          id
          originalSrc
          localFile {
            childImageSharp {
              fluid(maxWidth: 210) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        variants {
          price {
            amount
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
      }
    }
    biersafeImage: file(relativePath: { eq: "image-featured-biersafe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    weinsafeImage: file(relativePath: { eq: "image-featured-weinsafe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hopfenhoehle: file(
      relativePath: { eq: "image-featured-hopfenhoehle.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    captorImage: file(relativePath: { eq: "image-featured-captor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
