import React from 'react'
import Button from '~/components/00-global/Button/Button'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './featuredProducts.module.scss'
import BackgroundImage from 'gatsby-background-image'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'

import Badge from '~/images/icon-badge.svg'
import { Scroll } from '~/components/00-global/Animation/Animation'
import GetWindowDimensions from '~/components/00-global/util/getWindowDimensions'
import styleVariables from '~/layouts/variables.scss'

const FeaturedProducts = ({ products }) => {
  const md =
    typeof document !== `undefined` ? styleVariables.md.replace(/\D+/, '') : ''
  const { windowWidth } = GetWindowDimensions()
  const FeaturedProduct = (product, index) => {
    return (
      <Col key={product.products[0].id} className={styles.card}>
        <div className={styles.headerContainer}>
          <h2>{product.products[0].title.toUpperCase()}</h2>
        </div>
        <div className={styles.imageBadgeContainer}>
          {product.products[0].productType === 'Biersafe' && (
            <div className={styles.badge} aria-label='Bestseller'>
              <span>Bestseller</span>
              <img src={Badge} alt='' />
            </div>
          )}
          <Link
            to={`/produkt/${product.products[0].handle}`}
            className={styles.imageContainer}
          >
            <BackgroundImage
              className={styles.image}
              fluid={product.imageFluid}
            />
          </Link>
        </div>
        <div className={styles.buttonContainer}>
          <Scroll delay={windowWidth > md ? index * 100 : 0}>
            <Button
              asElement={Link}
              to={`/produkt/${product.products[0].handle}`}
            >
              <FormattedMessage id='shop.featuredProducts.button' />
            </Button>
          </Scroll>
        </div>
      </Col>
    )
  }

  return (
    <section className={styles.featuredProducts}>
      <Container fluid='xl' className={styles.container}>
        <Row>
          {products.map((product, index) => FeaturedProduct(product, index))}
        </Row>
        <hr className='separator' />
      </Container>
    </section>
  )
}

export default FeaturedProducts
