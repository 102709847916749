import React from 'react'
import styles from './byproducts.module.scss'
import { Container, Row, Col } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'
import getPrice from '~/components/00-global/util/price'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'
import { FadeInRight } from '~/components/00-global/Animation/Animation'

const Byproduct = (product, index) => {
  return (
    <Col
      className={styles.col}
      key={product.id}
      xs='auto'
      as={Link}
      to={`/produkt/${product.handle}`}
    >
      <FadeInRight delay={index * 50}>
        <div className={styles.imageContainer}>
          <BackgroundImage
            className={styles.image}
            fluid={product.images[0].localFile.childImageSharp.fluid}
          />
        </div>
        <div className={styles.details}>
          <h3>{product.title}</h3>
          {product.variants.length > 1 && (
            <span className={styles.hasVariants}>
              <FormattedMessage id='shop.byProducts.hasVariants' />
            </span>
          )}
          {product.priceRange.minVariantPrice.amount ===
          product.priceRange.maxVariantPrice.amount ? (
            <p>{getPrice(product.priceRange.minVariantPrice.amount)}</p>
          ) : (
            <p>
              <FormattedMessage id='shop.byProducts.priceRangePrefix' />{' '}
              {getPrice(product.priceRange.minVariantPrice.amount)}
            </p>
          )}
        </div>
      </FadeInRight>
    </Col>
  )
}

const Byproducts = ({ collections }) => {
  // Sort by adding an html comment to the collection in shopify. Example: <!--ORDER:2-->
  const customSort = (a, b) => {
    const digit = string =>
      string.match(/<!--ORDER:\d+-->/g)?.[0].match(/\d+/)?.[0]

    if (!digit(a.descriptionHtml) || !digit(b.descriptionHtml)) {
      return 0
    } else if (digit(a.descriptionHtml) < digit(b.descriptionHtml)) {
      return -1
    } else if (digit(a.descriptionHtml) > digit(b.descriptionHtml)) {
      return 1
    } else {
      return 0
    }
  }

  const sortedCollections = collections.sort(customSort)

  return (
    <section className={styles.byproducts}>
      <Container fluid='xl' className={styles.container}>
        {sortedCollections.map(collection => (
          <div key={collection.title} className={styles.byproducts}>
            <h2>{collection.title.toUpperCase()}</h2>
            <Row className={styles.row}>
              <div className={styles.overlayRight} />
              <div className={styles.overlayLeft} />
              <div className={styles.scrollContainer}>
                <div className={styles.scrollChild}>
                  {collection.products.map((product, index) =>
                    Byproduct(product, index)
                  )}
                </div>
              </div>
            </Row>
            <hr className='separator' />
          </div>
        ))}
      </Container>
    </section>
  )
}

export default Byproducts
